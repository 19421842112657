import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Layout from "../components/layoutMainCustom"
import BgImage from "../images/holiday_big.jpg"
import ChristmasEve from "../images/PerfectChristmas_Square.jpg"
import ChristmasCarnival from "../images/ChristmasCarnival.jpg"
import { Button, Collapse, Stack, Box } from "@chakra-ui/core"
import SEO from "../components/seo"
import PageHeaders from "../components/pageHeaders"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
`

function IndexPage() {
    const [show, setShow] = React.useState(false)
    const [show1, setShow1] = React.useState(false)
    const [show2, setShow2] = React.useState(false)

    const handleToggle1 = () => setShow1(!show1)
    const handleToggle = () => setShow(!show)
    const handleToggle2 = () => setShow2(!show2)
    {
        return (
            <Layout
                ImgSrc={BgImage}
                MainText="Christmas at Cross Church"
                SecondText="December 16th - December 24th"
            >
                <SEO title="Christmas at Cross Church" />
                <PageHeaders
                    css={css`
            display: flex;
          `}
                >
                    Christmas Carnivals
                </PageHeaders>
                <HeaderText></HeaderText>
                <div
                    css={css`
            display: flex;
            @media (max-width: 940px) {
              flex-direction: column;
            }
          `}
                >
                    <div>
                        <ParagraphText>
                            Join us for some Christmas season fun at any one of our four
                            campuses. Check the links below for more detail.
                        </ParagraphText>
                        <HeaderText>Surprise Campus</HeaderText>
                        <ParagraphText>
                            December 17th @ 5-8pm
                            <br />
                            <a href="https://facebook.com/events/s/surprise-christmas-carnival/355043793682246/">Click here for more info</a>
                        </ParagraphText>
                        <HeaderText>Phoenix Campus</HeaderText>
                        <ParagraphText>
                            December 16th @ 4-6pm
                            <br />
                            <a href="https://fb.me/e/3aOpfs3I7">Click here for more info</a>
                        </ParagraphText>
                        <HeaderText>Cornville Campus</HeaderText>
                        <ParagraphText>
                            December 17th @ 4-6pm
                            <br />
                            <a href="https://facebook.com/events/s/cornville-christmas-carnival/2027690850963338/">Click here for more info</a>
                        </ParagraphText>
                        <HeaderText>El Mirage Campus</HeaderText>
                        <ParagraphText>
                            December 17th @ 5-7pm
                            <br />
                            <a href="https://facebook.com/events/s/el-mirage-christmas-carnival/671313838142468/">Click here for more info</a>
                        </ParagraphText>
                    </div>
                    <img
                        src={ChristmasCarnival}
                        alt="Christmas Eve at Cross Church"
                        css={css`
              width: auto;
              height: 110px;
              margin-left: 4rem;
              border-radius: 6px;
              @media (max-width: 940px) {
                width: 100%;
                margin-top: 2rem;
                margin-left: 0;
                height: auto;
              }
            `}
                    />
                </div>
                <PageHeaders
                    css={css`
            display: flex;
            margin-top: 4rem;
          `}
                >
                    Christmas at Cross Church
                </PageHeaders>
                <HeaderText></HeaderText>
                <div
                    css={css`
            display: flex;
            @media (max-width: 940px) {
              flex-direction: column;
            }
          `}
                >
                    <div>
                        <ParagraphText>
                            This Christmas, Cross Church is offering 9 services across 4
                            different campuses. Join us live in-person at{" "}
                            <Link to="/surprise">Cross Church Surprise</Link>,{" "}
                            <Link to="/cornville">Cross Church Cornville</Link>,{" "}
                            <Link to="/phoenix">Cross Church Phoenix</Link>, or{" "}
                            <Link to="https://www.facebook.com/profile.php?id=100095517243288">Cross Church El Mirage</Link>.
                        </ParagraphText>
                        <HeaderText>Surprise Campus</HeaderText>
                        <ParagraphText>
                            December 23rd @ 5:00pm
                            <br />
                            December 24th @ 2:00pm, 3:30pm, and 5:00pm
                            <br />
                            <a href="https://fb.me/e/1JAWZpufU">Click here for more info</a>
                        </ParagraphText>
                        <HeaderText>Phoenix Campus</HeaderText>
                        <ParagraphText>
                            December 24th @ 2:00pm or 3:30pm
                            <br />
                            <a href="https://fb.me/e/5o78TLTnb">Click here for more info</a>
                        </ParagraphText>
                        <HeaderText>Cornville Campus</HeaderText>
                        <ParagraphText>
                            December 24th @ 3:30pm or 5:00pm
                            <br />
                            <a href="https://fb.me/e/8q4rRaleQ">Click here for more info</a>
                        </ParagraphText>
                        <HeaderText>El Mirage Campus</HeaderText>
                        <ParagraphText>
                            December 24th @ 10:30am
                            <br />
                            <a href="https://fb.me/e/1HP5dsWuU">Click here for more info</a>
                        </ParagraphText>
                    </div>
                    <img
                        src={ChristmasEve}
                        alt="Christmas Eve at Cross Church"
                        css={css`
              width: auto;
              height: 400px;
              margin-left: 4rem;
              border-radius: 6px;
              @media (max-width: 940px) {
                width: 100%;
                margin-top: 2rem;
                margin-left: 0;
                height: auto;
              }
            `}
                    />
                </div>
            </Layout>
        )
    }
}

export default IndexPage
